<template>
  <div>
    <div v-if="authorizationStatus === 'AUTHORIZED'">
      <HealtBookEdit
        v-if="showEdition"
        @resume-mode="showEdition = false"
        :patient="patient"
      ></HealtBookEdit>
      <HealtBookResume
        v-else
        @edit-mode="showEdition = true"
        :patient="patient"
      ></HealtBookResume>
    </div>
    <div v-if="!authorizationStatus">
      <button
        @click="requestHealthbookAuthorization"
        class="text-lg bg-dokBlue-ultra py-5 px-5 text-white border-0 rounded shadow cursor-pointer w-full font-EffraR my-4"
      >
        Demande d'accès au carnet de santé
      </button>
    </div>
    <div v-if="authorizationStatus === 'PENDING'">
      <button
        class="text-lg bg-green-400 py-5 px-5 text-white border-0 rounded shadow cursor-pointer w-full font-EffraR my-4"
      >
        Demande d'accès en attente
      </button>
    </div>
    <div v-if="authorizationStatus === 'REJECTED'">
      <button
        @click="requestHealthbookAuthorization"
        class="text-lg bg-green-400 py-5 px-5 text-white border-0 rounded shadow cursor-pointer w-full font-EffraR my-4"
      >
        Renvoyer une demande d'accès au carnet de santé
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const HealtBookEdit = () => import("./edition");
const HealtBookResume = () => import("./resume");
import { EventBus } from "@/event-bus";
export default {
  components: { HealtBookEdit, HealtBookResume },
  props: ["patient"],
  data() {
    return {
      showEdition: false,
      authorizationStatus: "PENDING"
    };
  },
  mounted() {
    EventBus.$on("health-book-notification", patientId => {
      this.refeshAuthorization(patientId);
    });
    this.refeshAuthorization(this.patient._id);
  },
  methods: {
    ...mapActions({
      checkAuthorization: "healthbook/CHECK_AUTHORIZATION",
      requestAuthorization: "authorization/REQUEST_AUTHORIZATION"
    }),
    refeshAuthorization(patientId) {
      const selfThis = this;
      if (patientId !== selfThis.patient._id) {
        return;
      }
      selfThis.checkAuthorization({
        patientId,
        onData: selfThis.onAuthorizationResult
      });
    },
    onAuthorizationResult({ data }) {
      //console.log(data);
      this.authorizationStatus = data;
    },
    requestHealthbookAuthorization() {
      this.$vs.loading();
      this.authorizationStatus = "PENDING";
      this.requestAuthorization({
        patientId: this.patient._id,
        authScope: "HEALTH-BOOK",
        onData: ({ message }) => {
          this.$vs.loading.close();
          this.authorizationStatus = "PENDING";
          this.$vs.notify({
            time: 8000,
            title: "",
            text: "Demande d'accès envoyée avec succès",
            color: "success",
            iconPack: "feather",
            icon: "icon-check"
          });
        }
      });
    }
  }
};
</script>
